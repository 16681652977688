import React, {useEffect, useState} from 'react';
import {DashboardLayout} from "../../components/Layout/Layout";
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {MDBDataTable} from 'mdbreact';
import {stageList, stageCategories, deleteStage, deleteStageCategory,getStage} from "../../services/StageService";
import AddStageModal from "../../components/Modals/AddStageModal";
import AddCategoryModal from "../../components/Modals/AddCategoryModal";
import {ifExpiredDisconnect} from "../../utils/toolbox";
import EditStageModal from "../../components/Modals/EditStageModal";



const AdminInscriptionStage = () => {
    const [stageModal, setStageModal] = useState(false);
    const [categoryModal, setCategoryModal] = useState(false);
    const [update, setUpdate] = useState(false);
    const [formatedList, setFormatedList] = useState([])
    const [categoriesList,setCategoriesList] = useState([])
    const [showCategoriesList,setShowCategoriesList] = useState(false)
    const [editStageModal, setEditStageModal] = useState(false);
    const [selectedStageToEdit, setSelectedStageToEdit] = useState(null);

    //use effect to fetch all stages
    useEffect(() => {
        ifExpiredDisconnect()
        async function loader() {
            const categories = await stageCategories()
            setCategoriesList(categories)
            const response = await stageList()
            if (response !== undefined) {
                //for each response assign the cateroy_id with the categories name
                response.forEach(stage => {
                    categories.forEach(category => {
                        if (stage.category_id === category.id) {
                            stage.category_id = category.name
                        }
                    })
                })
                setFormatedList(formatData(response))
            } else {
                setFormatedList([])
            }
        }

        loader()
    }, [update])




    const onUpdate = () => {
        setUpdate(!update)
    }
    const onCancelStageModal = () => {
        setStageModal(false);
    }
    const onCancelEditStageModal = () => {
        setEditStageModal(false);
    }
    const onCancelCategoryModal = () => {
        setCategoryModal(false);
    }

    const  onDeleteCategory = async (id) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cette catégorie de stage?")) {
            //delete the stage
            await deleteStageCategory(id)
            onUpdate()
        }
    }
    const onDeleteStage = async (id) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce stage ?")) {
            //delete the stage
            await deleteStage(id)
            onUpdate()
        }
    }

    const formatCategoryForSelect = () => {
        return categoriesList.map(category => {
            return {
                value: category.id,
                label: category.name
            }
        })
    }

    const onEditStage = async (id, data) => {
        //load the stage data and open AddCourseModal with props predefined
        const stage = await getStage(id);
        setEditStageModal(!editStageModal)
        setSelectedStageToEdit(stage)
        setUpdate(!update);
    }


    const formatData = (data) => {
        if (data !== undefined) {
            const formatDate = (date) => {
                date = date.split('-')
                date = date[2] + '/' + date[1] + '/' + date[0]
                return date
            }
            //for each stage format the begin and end date in format DD-MM-YYYY
            data.forEach(stage => {
                stage.begin_date = formatDate(stage.begin_date.split('T')[0])
                stage.end_date = formatDate(stage.end_date.split('T')[0])
                if (stage.full === true) {
                    stage.full = <span
                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">complet</span>
                } else {
                    stage.full = <span
                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">non</span>
                }
                if (stage.reserved === true) {
                    stage.reserved = <span
                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">reserver</span>
                } else {
                    stage.reserved = <span
                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">non</span>
                }
                stage.delete = <span> <button className="btn btn-danger p-1 inline-flex font-semibold  "
                                              onClick={() => onDeleteStage(stage.id)}>Supprimer</button>
                    </span>
                stage.edit = <span> <button className="btn btn-danger p-1 inline-flex font-semibold  "
                                              onClick={() => onEditStage(stage.id)}>editer</button>
                    </span>
                stage.ref = stage.reference.substring(0, 8)+stage.id
            })

            let table = {
                columns: [
                    {
                        label: 'id',
                        field: 'id',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'reference',
                        field: 'ref',
                        sort: 'asc',
                        width: 270
                    },
                    {
                        label: 'description',
                        field: 'description',
                        sort: 'asc',
                        width: 200
                    },
                    {
                        label: 'Start Date',
                        field: 'begin_date',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'End Date',
                        field: 'end_date',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Capactité max',
                        field: 'max_capacity',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'full',
                        field: 'full',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'prix',
                        field: 'price',
                        sort: 'asc',
                        width: 100
                    }, {
                        label: 'prix actif',
                        field: 'price_for_team_member',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'type',
                        field: 'type',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'category',
                        field: 'category_id',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'reserved',
                        field: 'reserved',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'editer',
                        field: 'edit',

                    },
                    {
                        label: 'supprimer',
                        field: 'delete',
                        sort: 'asc',

                    }
                ],
                rows: data
            }
            return table
        }
    }
    return (
        <DashboardLayout>
            <h2 className="absolute -top-8 left-10 font-sans  text-xl">Admin Inscription Stage</h2>
            <div className=" mt-14 ml-10 mr-10 content-center">
                <button
                    onClick={() => {
                        setStageModal(true)
                    }}
                    className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                    ajouter un stage
                </button>
                <button
                    onClick={() => {
                        setCategoryModal(true)
                    }}
                    className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                    ajouter une catégorie
                </button>
                {showCategoriesList === false ?
                <button
                    onClick={() => {
                        setShowCategoriesList(!showCategoriesList)
                    }}
                    className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                    afficher la liste des catégories
                </button>
                    :
                    <button
                        onClick={() => {
                            setShowCategoriesList(!showCategoriesList)
                        }}
                        className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                        cacher la liste des catégories
                    </button>
                }
                {showCategoriesList &&
                    //display a table of the categories and a button to remove a category for each tuple
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className=' text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"'>
                            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                            <tr>
                                <th scope="col" class="px-6 py-3">id</th>
                                <th scope="col" class="px-6 py-3">name</th>
                                <th>supprimer</th>
                            </tr>
                            </thead>
                            <tbody>
                            {categoriesList.map(category =>
                                <tr className="bg-gray-300 dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600" key={category.id}>
                                    <td className =" px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{category.id}</td>
                                    <td className ="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" >{category.name}</td>
                                    <td className = 'px-6 py-4'><button className="btn btn-danger p-1 inline-flex font-semibold  "
                                                 onClick={() => onDeleteCategory(category.id)}>Supprimer</button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                }
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <MDBDataTable theadTextWhite={true} tbodyTextWhite={true} striped bordered sortable
                                  data={formatedList} noBottomColumns={true} searching={true}/>
                </div>
            </div>
            {stageModal &&
                <AddStageModal onCancel={onCancelStageModal} onUpdate={onUpdate}/>
            }
            {categoryModal &&
                <AddCategoryModal onCancel={onCancelCategoryModal} onUpdate={onUpdate}/>
            }
            {editStageModal &&
                <EditStageModal onCancel={onCancelEditStageModal} onUpdate={onUpdate} stage={selectedStageToEdit} categoriesList={formatCategoryForSelect()}/>
            }
        </DashboardLayout>
    )
}

export default AdminInscriptionStage;