import React, { useEffect, useState } from "react";
import Portal from "../Portal/Portal";
import { ifExpiredDisconnect } from "../../utils/toolbox";
import * as api from "../../services/AccountService";
import DataTable from "react-data-table-component";
import { DataTableTheme } from "../DataTableComponents/DataTableTheme";
import { getInscriptionsFromOrder } from "../../services/OrderService";
import { getCourse, getCoursesList } from "../../services/CourseService";
import {
  manual_inscription_stage,
  stageList,
} from "../../services/StageService";
import {
  manual_inscription_subscription,
  subscription_list,
} from "../../services/SubscriptionService";
import Select from "react-select";
import {
  get_inscription_course,
  manual_inscription_course,
} from "../../services/InscriptionCoursesService";
import { getLocationList } from "../../services/LocationService";
import EditAccount from "./EditAccount";
import { useNavigate } from "react-router-dom";
import { getSection } from "../../services/SectionService";

const AccountDetailAdmin = ({ onCancel, row, onUpdate }) => {
  const navigate = useNavigate();
  const [childrenList, setChildrenList] = useState();
  const [ordersHistoric, setOrdersHistoric] = useState([]);
  const [inscriptionsContent, setInscriptionsContent] = useState();
  const [currentRow, setCurrentRow] = useState(null);
  const [lastName, setLastName] = useState(row.last_name);
  const [firstName, setFirstName] = useState(row.first_name);
  const [email, setEmail] = useState(row.email);
  const [phone, setPhone] = useState(row.phone);
  const [birthDate, setBirthDate] = useState(row.birth_date);
  const [manualInscription, setManualInscription] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [courseOptions, setCourseOptions] = useState();
  const [stageOptions, setStageOptions] = useState();
  const [subscriptionOptions, setSubscriptionOptions] = useState();
  const [selectedToInscr, setSelectedToInscr] = useState();
  const [update, setUpdate] = useState(false);
  const [error, setError] = useState();
  const [editAccountModal, setEditAccountModal] = useState(false);
  const [outputMessage, setOutputMessage] = useState();
  const [allManualInscriptions, setAllManualInscriptions] = useState([]);

  const defaultOptions = [
    { value: "cours", label: "cours" },
    { value: "abonnement", label: "abonnement" },
    { value: "stage", label: "stage" },
  ];

  const handleEditAccountModal = () => {
    setEditAccountModal(!editAccountModal);
  };
  const onCancelEditAccountModal = () => {
    setEditAccountModal(false);
  };

  useEffect(() => {
    ifExpiredDisconnect();
    const loadManualInscriptions = async () => {
      let childrenList = await api.getChildrens(row.id);
      setChildrenList(childrenList);
      let manualInscriptions = [];
      //for each children call the route api.getInscriptionsHistory(children_id) and if there's inscription that has no order_id add it in allManualInscriptions
      childrenList.forEach((child) => {
        api.getInscriptionsHistory(child.id).then((inscriptions) => {
          inscriptions.forEach((inscription) => {
            if (inscription.order_id === null) {
              //edit allManualInscriptions to include it at the end
              manualInscriptions.push(inscription);
            }
          });
        });
      });
      api.getInscriptionsHistory(row.id).then((inscriptions) => {
        inscriptions.forEach((inscription) => {
          if (inscription.order_id === null) {
            //edit allManualInscriptions to include it at the end
            manualInscriptions.push(inscription);
          }
        });
      });
      setAllManualInscriptions(manualInscriptions);
      const orders = await api.getOrdersHistory(row.id);
      const ordersFormater = () => {
        //for each order format date from 2024-01-20T12:55:50.000Z to dd/mm/YY and add time param hh:mm:ss
        orders.forEach((order) => {
          if (order !== null) {
            if (order.order_date) {
              let pretime = order.order_date.split("T")[1];
              order.order_time = pretime.split(".")[0];
              order.order_date = order.order_date
                .split("T")[0]
                .split("-")
                .reverse()
                .join("/");
            }
            if (order.status === "STRIPE_CHECKOUT_SESSION_COMPLETED") {
              order.status = (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  Paid
                </span>
              );
            } else if (order.status === "PAYMENT_CREATED") {
              order.status = (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">
                  Pending
                </span>
              );
            } else if (order.status === "PAYMENT_FAILED") {
              order.status = (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                  Failed
                </span>
              );
            } else if (order.status === "CREATED") {
              order.status = (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">
                  panier
                </span>
              );
            } else if (order.status === "STRIPE_CHECKOUT_SESSION_EXPIRED") {
              order.status = (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                  Payment expirer
                </span>
              );
            }
          }
        });
        if (manualInscriptions.length > 0) {
          let totals = manualInscriptions.reduce(
            (acc, inscription) => acc + inscription.price,
            0
          );
          orders.push({
            id: -1,
            reference: "manual inscription",
            total_price: totals,
            billing_email: "manual inscription",
            order_date: "manual inscription",
            status: "manual inscription",
            order_time: "manual inscription",
          });
        }
        return orders;
      };
      setOrdersHistoric(ordersFormater());
    };

    loadManualInscriptions();

    Promise.all([
      getCoursesList(),
      stageList(),
      subscription_list(),
      getLocationList(),
    ])
      .then((values) => {
        //put all list in the state allEVPossibleInscription which is an array
        let products = [];
        products.push(values[0]);
        //format course list for select component
        let courses = [];
        let locations = values[3];
        values[0].forEach((course) => {
          // get the location of the course
          let location = locations.find((loc) => loc.id === course.location_id);
          let name =
            course.week_day +
            " " +
            course.section.description +
            " " +
            course.begin_time.split(":")[0] +
            "h " +
            location.name;
          courses.push({ value: course.id, label: name });
        });
        setCourseOptions(courses);
        products.push(values[0]);
        let stages = [];
        values[1].forEach((stage) => {
          stages.push({ value: stage.id, label: stage.description });
        });
        setStageOptions(stages);
        products.push(values[4]);
        let subscriptions = [];
        values[2].forEach((subscription) => {
          subscriptions.push({
            value: subscription.id,
            label: subscription.description,
          });
        });

        setSubscriptionOptions(subscriptions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [update]);

  const loadInscriptionsFromOrder = async (row) => {
    setCurrentRow(row);
    setInscriptionsContent([]);

    let inscriptions = {
      inscriptionsCourses: [],
      inscriptionsTrainingCourses: [],
      inscriptionsSubscriptions: [],
      inscriptionsMembers: [],
      inscriptionsMembersCab: [],
    };

    if (row.id === -1) {
      allManualInscriptions.forEach((inscr) => {
        if (inscr.type === "course") {
          inscriptions.inscriptionsCourses.push({ inscription: inscr });
        } else if (inscr.type === "subscription") {
          inscriptions.inscriptionsSubscriptions.push({ inscription: inscr });
        } else if (inscr.type === "training_course") {
          inscriptions.inscriptionsTrainingCourses.push({ inscription: inscr });
        } else if (inscr.type === "member") {
          inscriptions.inscriptionsMembers.push({ inscription: inscr });
        } else if (inscr.type === "member_cab") {
          inscriptions.inscriptionsMembersCab.push({ inscription: inscr });
        }
      });
    } else {
      inscriptions = await getInscriptionsFromOrder(row.id);
    }

    let inscriptionsContainer = [];
    if (inscriptions.inscriptionsCourses.length > 0) {
      for (const inscr of inscriptions.inscriptionsCourses) {
        // Add null check for inscr.course
        if (inscr.course) {
          inscr.inscription.description =
            inscr.course.week_day +
            " " +
            inscr.course.begin_time.substring(0, 5);
          inscr.inscription.section = inscr.section?.description || ""; // Optional chaining to handle undefined section
          inscriptionsContainer.push(inscr.inscription);
        } else {
          //when here whe should call inscription course /:courseId => get the course id => then call get course /:id and then => call section /:id to get the refering section
          let inscription_course = await get_inscription_course(
            inscr.inscription.id
          );
          let course = await getCourse(inscription_course.course_id);
          let section = await getSection(course.section_id);
          inscr.course = course;
          inscr.section = section;
          inscr.inscription.description =
            inscr.course.week_day +
            " " +
            inscr.course.begin_time.substring(0, 5);
          inscr.inscription.section = inscr.section?.description || "";
          inscriptionsContainer.push(inscr.inscription);
        }
      }
    }
    if (inscriptions.inscriptionsTrainingCourses.length > 0) {
      inscriptions.inscriptionsTrainingCourses.forEach((inscr) => {
        if (inscr.trainingCourse) {
          inscr.inscription.description = inscr.trainingCourse.description;
          inscriptionsContainer.push(inscr.inscription);
        } else {
          console.warn(
            "Training course data is missing for inscription: ",
            inscr
          );
        }
      });
    }
    if (inscriptions.inscriptionsSubscriptions.length > 0) {
      inscriptions.inscriptionsSubscriptions.forEach((inscr) => {
        inscriptionsContainer.push(inscr.inscription);
      });
    }
    if (inscriptions.inscriptionsMembers.length > 0) {
      inscriptions.inscriptionsMembers.forEach((inscr) => {
        inscriptionsContainer.push(inscr.inscription);
      });
    }
    if (inscriptions.inscriptionsMembersCab.length > 0) {
      inscriptions.inscriptionsMembersCab.forEach((inscr) => {
        if (inscr.inscription && inscr.memberTypeCab) {
          inscr.inscription.type =
            inscr.inscription.type + "_" + inscr.memberTypeCab.name;
          inscriptionsContainer.push(inscr.inscription);
        } else {
          console.warn("Member cab data is missing for inscription: ", inscr);
        }
      });
    }

    if (
      inscriptions.inscriptionsMembersCab.length === 0 &&
      inscriptions.inscriptionsMembers.length === 0 &&
      inscriptions.inscriptionsSubscriptions.length === 0 &&
      inscriptions.inscriptionsTrainingCourses.length === 0 &&
      inscriptions.inscriptionsCourses.length === 0
    ) {
      inscriptionsContainer = [
        {
          id: "created",
          type: "unfinished",
          begin_date: "aucun",
          end_date: "aucun",
          inscription_date: "aucun",
        },
      ];
    }

    inscriptionsContainer.forEach((inscription) => {
      if (inscription.begin_date) {
        inscription.begin_date = inscription.begin_date
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/");
      }
      if (inscription.end_date) {
        inscription.end_date = inscription.end_date
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/");
      }
      if (inscription.inscription_date) {
        inscription.inscription_date = inscription.inscription_date
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/");
      }
      if (inscription.type === "training_course") {
        inscription.type = "stage";
      }
      if (inscription.type === "subscription") {
        inscription.type = "abonnement";
        inscription.description = "abonnement";
      }
      if (inscription.type === "member_cab_indoor") {
        inscription.type = "cab indoor";
      }
      if (inscription.type === "membre_cab_outdoor") {
        inscription.type = "cab outdoor";
      }
      if (inscription.type === "member") {
        inscription.type = "membre";
        inscription.description = "cotisation";
      }
    });

    setInscriptionsContent(inscriptionsContainer);
  };

  let orderColumn = {
    columns: [
      {
        name: "id",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "date",
        selector: (row) => row.order_date,
        sortable: true,
      },
      {
        name: "time",
        selector: (row) => row.order_time,
        sortable: true,
      },
      {
        name: "total",
        selector: (row) => row.total_price,
        sortable: true,
      },
      {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
      },
    ],
  };

  let inscriptionColumn = {
    columns: [
      {
        name: "compte",
        selector: (row) => row.account_id,
      },
      {
        name: "start",
        selector: (row) => row.begin_date,
        grow: 2,
      },
      {
        name: "end",
        selector: (row) => row.end_date,
        grow: 2,
      },
      {
        name: "id inscirption",
        selector: (row) => row.id,
      },
      {
        name: "date",
        selector: (row) => row.inscription_date,
        grow: 2,
      },
      {
        name: "type",
        selector: (row) => row.type,
        grow: 2,
      },
      {
        name: "section",
        selector: (row) => row.section,
        grow: 4,
      },
      {
        name: "description",
        selector: (row) => row.description,
        grow: 3,
      },
      {
        name: "prix",
        selector: (row) => row.price,
      },
      {
        name: "reduction",
        selector: (row) => row.reduction,
      },
      {
        name: "cancelled",
        selector: (row) => row.cancel,
      },
    ],
  };

  const handleManuallyRegister = async () => {
    try {
      let result;
      if (selectedOption.value === "cours") {
        result = await manual_inscription_course(row.id, selectedToInscr.value);
      } else if (selectedOption.value === "stage") {
        result = await manual_inscription_stage(row.id, selectedToInscr.value);
      } else if (selectedOption.value === "abonnement") {
        result = await manual_inscription_subscription(
          row.id,
          selectedToInscr.value
        );
      }

      if (result === "ERR_NETWORK") {
        navigate("/server_maintenance");
      }
      setOutputMessage(
        "Inscrit ✅, les modifications sont visibles dans l'histoirique des inscriptions du membre"
      );
      setTimeout(() => setOutputMessage(""), 3000);
    } catch (e) {
      setError(e.response.data.message);
      console.error(e);
      setTimeout(() => {
        setError("");
        setOutputMessage("");
      }, 3000);
    }
    setUpdate(!update);
  };

  DataTableTheme();

  const ExpandedComponent = () => {
    return (
      <pre className="w-max">
        <DataTable
          columns={inscriptionColumn.columns}
          data={inscriptionsContent}
          showGridlines
          highlightOnHover
        />
      </pre>
    );
  };

  //align on left a column with the row info and on left the list of inscriptions
  return (
    <>
      <div className="fixed inset-0 bg-black opacity-25 " />
      <Portal className="overflow-auto scroll-auto">
        <div
          onClick={onCancel}
          className="bg-black opacity-25 fixed z-10 inset-0"
        />
        <div className="flex flex-col bg-backgroundEV w-full tablet:w-4/5 text-white rounded-lg pt-8 px-4 pb-4 fixed inset-0 z-50 m-auto h-fit max-h-[90vh] overflow-auto">
          <button className="absolute right-4 top-4" onClick={onCancel}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-x"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>
          <div className="pb-4 space-x-2">
            <span className="font-bold"> compte:</span>
            {row.active === true ? (
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                actif
              </span>
            ) : (
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                inactif
              </span>
            )}
          </div>
          <div className="grid grid-cols-1 laptop:grid-cols-2 gap-8 ">
            <ul className="flex flex-col">
              <li className="space-x-2">
                <span className="font-bold">ID:</span> {row.id}
              </li>
              <li className="space-x-2">
                <span className="font-bold">Nom et prénom: </span>
                {lastName}, {firstName}
              </li>
              <li className="space-x-2">
                <span className="font-bold">Date de naissance:</span>
                {birthDate}
              </li>
              {email && (
                <li className="space-x-2">
                  <span className="font-bold">Email:</span>
                  <span> {row.email} </span>
                </li>
              )}
              {phone && (
                <li className="space-x-2">
                  <span className="font-bold">Téléphone:</span>
                  <span>{row.phone}</span>
                </li>
              )}
              <li className="space-x-2">
                <span className="font-bold">Addresse:</span>{" "}
                {row.house_number +
                  " " +
                  row.street +
                  " " +
                  row.locality +
                  " " +
                  row.postal_code +
                  " boite" +
                  row.box}
              </li>
              <li className="space-x-2">
                <span className="font-bold">ville:</span> {row.locality}
              </li>
              <li className="space-x-2">
                <span className="font-bold">Sexe:</span> {row.gender}
              </li>
              <li className="space-x-2">
                <span className="font-bold">Club:</span> {row.club}
              </li>
              <li className="space-x-2">
                <span className="font-bold">Rentrer seul:</span>{" "}
                {row.allow_going_home_alone}
              </li>
              {!row.allow_going_home_alone && (
                <li className="space-x-2">
                  <span className="font-bold ">Responsible:</span>{" "}
                  {row.responsible_of_retrieving}
                </li>
              )}
              {row.parent_id.props.children[1] !== null && (
                <ul className="flex flex-wrap">
                  <li>
                    <span className="font-bold">ParentId:</span>
                    <div className="underline hover:text-pinkEv">
                      <a href="">{row.parent_id.props.children[1]}</a>
                    </div>
                  </li>
                </ul>
              )}
              <li className="space-x-2">
                <span className="font-bold">Niveau:</span> {row.level}
              </li>
              <li>
                <span className="font-bold pe-2">T-shirt:</span>
                {row.t_shirt}
              </li>
              <div className="grid grid-cols-1 pt-4 gap-y-4">
                <button
                  onClick={handleEditAccountModal}
                  className=" bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors duration-500 ease-in-out"
                >
                  modifier
                </button>
                <button
                  onClick={() => {
                    setManualInscription(!manualInscription);
                    setSelectedOption(null);
                  }}
                  className=" bg-gray-300 hover:bg-red-700 text-gray-700 hover:text-white font-bold py-2 px-4 rounded transition-colors duration-500 ease-in-out"
                >
                  inscrire manuellement
                </button>
              </div>
            </ul>
            {
              //table with all the children next to the last list
            }
            <div className="flex flex-column space-y-8">
              <form
                className="space-y-4"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleManuallyRegister();
                }}
              >
                {manualInscription && (
                  <>
                    <h3 className="text-xl font-bold">Inscription manuelle</h3>
                    <div className="grid gap-4 text-black w-full ">
                      <Select
                        options={defaultOptions}
                        onChange={setSelectedOption}
                        styles={selectStyles}
                      />
                      {selectedOption && (
                        <>
                          {selectedOption.value === "abonnement" && (
                            <Select
                              options={subscriptionOptions}
                              onChange={setSelectedToInscr}
                              styles={selectStyles}
                            />
                          )}
                          {selectedOption.value === "cours" && (
                            <Select
                              options={courseOptions}
                              onChange={setSelectedToInscr}
                              styles={selectStyles}
                            />
                          )}
                          {selectedOption.value === "stage" && (
                            <Select
                              options={stageOptions}
                              onChange={setSelectedToInscr}
                              styles={selectStyles}
                            />
                          )}

                          <button
                            type="submit"
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors duration-500 ease-in-out"
                          >
                            inscrire
                          </button>
                          {outputMessage && (
                            <p className="rounded bg-pinkEv-800 text-white shadow-lg ">
                              {outputMessage}
                            </p>
                          )}
                          {error && (
                            <p className="rounded bg-pinkEv-800 text-white shadow-lg border-[1px] border-red-400 px-2 py-1">
                              <span className="text-red-400">Sorry :</span>{" "}
                              {error}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
          {childrenList?.length > 0 ? (
            <section className="pt-8">
              <h2 className="text-xl font-bold">Membre(s) de la famille</h2>
              {childrenList.map((child) => (
                <ul key={child.id}>
                  <li>
                    <span className="font-bold pe-2">Nom et prénom: </span>
                    {`${child.last_name}, ${child.first_name}`}
                  </li>
                  <li>
                    <span className="font-bold pe-2">ID: </span>
                    {child.id}
                  </li>
                </ul>
              ))}
            </section>
          ) : null}

          {
            //lister les inscriptions
          }
          <div className="pt-8">
            {ordersHistoric.length !== 0 && (
              <div className=" m-auto flex flex-column">
                <h2 className="text-xl font-bold">
                  Historique des inscriptions
                </h2>
                <div className="mt-2">
                  <DataTable
                    columns={orderColumn.columns}
                    data={ordersHistoric}
                    expandableRows
                    expandableRowExpanded={(row) => row === currentRow}
                    onRowClicked={(row) => setCurrentRow(row)}
                    expandableRowsComponent={ExpandedComponent}
                    onRowExpandToggled={(bool, row) =>
                      loadInscriptionsFromOrder(row)
                    }
                    showGridlines
                    pagination
                    highlightOnHover
                    paginationRowsPerPageOptions={[1, 5, 10, 20, 25, 50, 100]}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {editAccountModal && (
          <EditAccount onCancel={onCancel} onUpdate={onUpdate} account={row} />
        )}
      </Portal>
    </>
  );
};

const selectStyles = {
  control: (baseStyles) => ({
    ...baseStyles,
    width: "full",
  }),
};

export default AccountDetailAdmin;
