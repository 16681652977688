// this array contains the different level values
const url = "https://ev-api.ev-club-inscriptions.be/";
// const url = "http://localhost:3000/";
const LEVEL_OPTIONS = [
    {value: '', label: ''},
    {value: '4', label: '4'},
    {value: '5a', label: '5a'},
    {value: '5b', label: '5b'},
    {value: '5c', label: '5c'},
    {value: '6a', label: '6a'},
    {value: '6b', label: '6b'},
    {value: '6c', label: '6c'},
    {value: '7a', label: '7a'},
    {value: '7b', label: '7b'},
    {value: '7c', label: '7c'},
    {value: '8a', label: '8a'},
    {value: '8b', label: '8b'},
    {value: '8c', label: '8c'},
]
// this array contains the different t-shirt values
const T_SHIRT_OPTIONS = [
    {value: 'S', label: 'S'},
    {value: 'M', label: 'M'},
    {value: 'L', label: 'L'},
    {value: 'XL', label: 'XL'}
]

const LEVEL_RANKS ={
    "":0,
    "4":1,
    "5a":2,
    "5b":3,
    "5c":4,
    "6a":5,
    "6b":6,
    "6c":7,
    "7a":8,
    "7b":9,
    "7c":10,
    "8a":11,
    "8b":12,
    "8c":13,
}
const GENDERS = [
    'M',
    'F',
    'X'
]

const WEEKDAYS = [
    {value: 'lundi', label: 'lundi'},
    {value: 'mardi', label: 'mardi'},
    {value: 'mercredi', label: 'mercredi'},
    {value: 'jeudi', label: 'jeudi'},
    {value: 'vendredi', label: 'vendredi'},
    {value: 'samedi', label: 'samedi'},
    {value: 'dimanche', label: 'dimanche'}
]

const LANG_OPTIONS = [
    {value: 'FR', label: 'FR'},
    {value: 'NL', label: 'NL'},
    {value: 'EN', label: 'EN'}
]
const TYPE_STAGE = [
    {value: 0, label: 'indoor'},
    {value: 1, label: 'outdoor'},
]




const TOKEN = "token";
const RIGHT = "right";

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

export {T_SHIRT_OPTIONS, LEVEL_OPTIONS, GENDERS, TOKEN, RIGHT, classNames, url, WEEKDAYS,LANG_OPTIONS,LEVEL_RANKS,TYPE_STAGE};
